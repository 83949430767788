import React, { useEffect, useState } from 'react';
import { getDeviceId } from 'utils/getDeviceId';
import {
  createBulkFeedbackLogs,
  createFeedbackLog,
  getTrackId,
} from 'api/feedbackLogs';
import {
  IFeedbackLogRequest,
  ITrackIdRequest,
  TAction,
  TSection,
  TTarget,
} from 'types/feedbackLogs';
import { deleteCookie } from 'utils/cookie';
import { getLaunchTime, getMobileOS, getPage } from 'utils/feedbackLogUtils';
import { version } from '../../package.json';

// TrackId를 발급받기 위한 피드백 로그 hook.
const useGetTrackId = () => {
  const [sessionTrack, setSessionTrack] = useState(() =>
    sessionStorage.getItem('track'),
  );

  useEffect(() => {
    (async () => {
      try {
        const userAgent = navigator.userAgent;
        if (!userAgent) return null;

        const isMobile = /Mobile/i.test(userAgent);
        let deviceId = null;
        let previousTrackId = null;

        if (sessionTrack) {
          const jsonTrack = JSON.parse(sessionTrack);

          const isFromApp = jsonTrack.entry === 'fromApp';

          // 앱에서 최초 진입한 경우. trackId 추가 발급 안함.
          if (isFromApp) {
            delete jsonTrack.entry;
            sessionStorage.setItem('track', JSON.stringify(jsonTrack));
            deleteCookie('trackId');
            return;
          }

          if (jsonTrack.trackId) {
            previousTrackId = jsonTrack.trackId;
          }
        }

        if (!deviceId && isMobile) {
          deviceId = getDeviceId();
        }

        const body: ITrackIdRequest = {
          deviceId,
          deviceType: isMobile ? 'mobile' : 'pc',
          platform: isMobile ? getMobileOS() : 'web',
          os_version: userAgent,
          front_version: version,
          issuedTimestamp: new Date().getTime(),
          issuedPage: getPage(window.location.pathname),
          issuedPageUrl: window.location.href,
          previousTrackId,
        };

        const newTrackId = await getTrackId(body);
        if (newTrackId) {
          const trackBody = {
            trackId: newTrackId,
            trackTime: new Date(),
            launchTime: getLaunchTime(),
          };
          sessionStorage.setItem('track', JSON.stringify(trackBody));
        }
      } catch (error: any) {
        console.log('useGetTrackId ERROR : ', error);
      }
    })();
  }, []);
};

export default useGetTrackId;

export const postFeedbackLog = async ({
  action,
  actionParam,
  target,
  targetId,
  targetParam,
  targetType,
  targetSubtype,
  visitedPages,
  section,
  sectionId,
  sectionParam,
  sectionType,
  sectionSubtype,
  pageId,
}: {
  action: TAction;
  actionParam?: any;
  target?: string;
  targetId?: string | null;
  targetParam?: any;
  targetType?: string | boolean;
  targetSubtype?: string | boolean;
  visitedPages: string[];
  section?: string | null;
  sectionId?: string | null;
  sectionParam?: any;
  sectionType?: string;
  sectionSubtype?: string;
  pageId?: string;
}) => {
  try {
    const sessionTrack = sessionStorage.getItem('track');
    if (!sessionTrack) return;

    const jsonTrack = JSON.parse(sessionTrack);

    const prevPageIndex = visitedPages.length - 2;
    let refererUrl = '';

    if (prevPageIndex >= 0) {
      refererUrl = visitedPages[prevPageIndex];
    }

    await createFeedbackLog({
      trackId: jsonTrack.trackId,
      trackTime: jsonTrack.trackTime,
      launchTime: getLaunchTime(),
      timestamp: new Date().getTime(),
      action,
      actionParam,
      target,
      targetId,
      targetParam,
      targetType,
      targetSubtype,
      page: getPage(window.location.pathname),
      pageUrl: window.location.href,
      referer: getPage(refererUrl),
      refererUrl,
      section,
      sectionId,
      sectionParam,
      sectionType,
      sectionSubtype,
      pageId,
    });
  } catch (error) {
    console.log('postFeedbackLog ERROR : ', error);
  }
};

export const postBulkFeedbackLogs = async (
  paramsArray: IFeedbackLogRequest[],
) => {
  try {
    await createBulkFeedbackLogs(paramsArray);
  } catch (error) {
    console.log('postBulkFeedbackLogs ERROR : ', error);
  }
};
